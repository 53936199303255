import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	setCurrentGroupIndex,
	setCurrentTabIndex,
	setQuestionNumber,
	setScaleDisplayMode,
	setSectionId,
	setTabs,
} from "../../../store/Navigation/NavSlice";
import { RootState } from "../../../store";

import { Welcome } from "./SurveyWelcomeComponent";
import { SectionCard } from "./SectionCard";
import { SurveySectionProps } from "./SurveySectionComponent";

import "./SurveySection.scss";
import { QuestionListItem, ScaleDisplayModes, SurveySection } from "../../../@types/surveys.d";
import { getTabs } from "../store/TabbedSectionState";

export const SurveyQuestionsComponent: React.FC<SurveySectionProps> = ({
	centerQuestions,
}) => {
	const dispatch = useDispatch();

	const { activeSurvey, currentSection, questionList } = useSelector(
		(state: RootState) => state.user
	);
	const { currentGroupIndex, currentTabIndex } = useSelector(
		(state: RootState) => state.navigation
	);

	useEffect(() => {
		dispatch(setSectionId(""));
		dispatch(setQuestionNumber(0));
	}, [activeSurvey]);

	useEffect(() => {
		if (currentSection && questionList) {
			if (currentSection?.questionGroups?.length > 0 && currentGroupIndex < 0) {
				dispatch(setCurrentGroupIndex(0));
			}
			if (currentTabIndex === -1) {
				dispatch(setCurrentTabIndex(0));
			}
			// Remove tabs for assessments that don't use them
			switch (activeSurvey?.surveyDocument?.survey?.instrumentId) {
				case 2947: // Workplace Big 5 - 5.0 settings
					dispatch(setScaleDisplayMode(ScaleDisplayModes.AlignHorizontally));
					dispatch(setTabs([]));
					break;
				case 2948: // REAL Companion settings
					dispatch(setTabs([]));
					break;

				default:
					updateTabs(currentSection, questionList);
			}
		}
	}, [currentSection]);

	const updateTabs = (
		currentSection: SurveySection,
		questionList: QuestionListItem[]
	) => {
		let tabbedSections = getTabs(currentSection, questionList);
		if (tabbedSections.length) {
			if (tabbedSections.length === 1) {
				dispatch(setTabs([]));
				return;
			}
			dispatch(setTabs(tabbedSections));
		}
	};

	return (
		<Fragment>
			<Welcome />
			{currentSection?.questionGroups && (
				<SectionCard centerQuestions={centerQuestions} />
			)}
		</Fragment>
	);
};
