import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { Button, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import {
	setCurrentGroupIndex,
	setCurrentTabIndex,
} from "../../store/Navigation/NavSlice";

const TabbedNavigation: React.FC = () => {
	const { tabs, currentTabIndex } = useSelector(
		(state: RootState) => state.navigation
	);

	const dispatch = useDispatch();

	const theme = useTheme();

	const changeTab = (index: number) => {
		if (currentTabIndex < index) {
			dispatch(setCurrentTabIndex(currentTabIndex + index));
			if (tabs[index].questionGroupIndexes.length >= 1) {
				dispatch(setCurrentGroupIndex(tabs[index].questionGroupIndexes[0]));
			} else {
				dispatch(setCurrentGroupIndex(currentTabIndex + index));
			}
		} else if (currentTabIndex > index) {
			if (index === 0) {
				dispatch(setCurrentTabIndex(index));
				if (tabs[index].questionGroupIndexes.length >= 1) {
					dispatch(setCurrentGroupIndex(tabs[index].questionGroupIndexes[0]));
				} else {
					dispatch(setCurrentGroupIndex(index));
				}
			} else {
				dispatch(setCurrentTabIndex(currentTabIndex - index));
				if (tabs[index].questionGroupIndexes.length >= 1) {
					dispatch(setCurrentGroupIndex(tabs[index].questionGroupIndexes[0]));
				} else {
					dispatch(setCurrentGroupIndex(currentTabIndex - index));
				}
			}
		} else {
			dispatch(setCurrentTabIndex(index));
			if (tabs[index].questionGroupIndexes.length > 1) {
				dispatch(setCurrentGroupIndex(tabs[index].questionGroupIndexes[0]));
			} else {
				dispatch(setCurrentGroupIndex(index));
			}
		}
		window.scrollTo(0, 0);
	};

	return (
		<Grid>
			<Stack mr={2} direction="row" alignSelf="center" justifyContent="center">
				{tabs.length &&
					tabs.map((item, index) => {
						return (
							<Button
								key={index}
								className="tab-icon"
								variant="text"
								size="small"
								sx={{ backgroundColor: currentTabIndex === index ? theme.palette.secondary.light: theme.palette.primary.light, "&:hover": {
									backgroundColor: theme.palette.secondary.light,
									borderRadius: "10px",
								} }}
								onClick={() => changeTab(index)}
							>
								<Typography
									sx={{ color: theme.palette.text.primary, fontWeight: currentTabIndex === index ? 700 : 500, }}
									key={index}
									variant="caption2"
								>
									{item.tabText}
								</Typography>
							</Button>
						);
					})}
			</Stack>
		</Grid>
	);
};

export default TabbedNavigation;
