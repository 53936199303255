import { configureStore } from '@reduxjs/toolkit';
import userReducer from './User/UserSlice';
import navReducer from './Navigation/NavSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        navigation: navReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;