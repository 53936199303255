import { Fragment } from "react";

import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useEnv } from "../context/env.context";

/* istanbul ignore next */
const UserInfo: React.FC = () => {
    const { user } = useAuth0();
    const {
        domain,
        clientId,
        webAppUrl,
        apiServerUrl,
        applicationId,
        tenantId,
        environment,
    } = useEnv();

    let idToken = localStorage.getItem("token") || "";

    return (
        <Fragment>
            <Card sx={{ maxWidth: "100%" }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        <Grid container>
                            <Grid item xs={6}>
                                Who are you?
                            </Grid>
                            <Grid item xs={6} className="right">
                                {moment().format("MMMM D, YYYY")}
                            </Grid>
                        </Grid>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        <Grid container>
                            <Grid item xs={3}>
                                <label>Domain:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {domain}
                            </Grid>
                            <Grid item xs={3}>
                                <label>ClientId:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {clientId}
                            </Grid>
                            <Grid item xs={3}>
                                <label>App Root:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {webAppUrl}
                            </Grid>
                            <Grid item xs={3}>
                                <label>API Root:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {apiServerUrl}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Application ID:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {applicationId}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Tenant ID:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {tenantId}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Environment:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {environment}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Nickname:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {user?.nickname}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Name:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {user?.name}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Picture:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {user?.picture}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Updated_at:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {user?.updated_at}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Email:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {user?.email}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Email_verified:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {user?.email_verified ? "Yes" : "No"}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Sub:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {user?.sub}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Process:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {JSON.stringify(process?.env)}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Environment:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                {process.env.REACT_APP_ENVIRONMENT}
                            </Grid>
                            <Grid item xs={3}>
                                <label>Full Token:</label>
                            </Grid>
                            <Grid item xs={9} className="left">
                                <a
                                    rel="noreferrer"
                                    href={"https://jwt.io/?token=" + idToken}
                                    target="_blank"
                                >
                                    View on JWT.io
                                </a>
                            </Grid>
                            {user && (
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <label>Enterprise ID:</label>
                                        </Grid>
                                        <Grid item xs={9} className="left">
                                            {user["http://schemas.ccl.org/accounts/claims/ccl-identity/id"]}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label>Given Name:</label>
                                        </Grid>
                                        <Grid item xs={9} className="left">
                                            {user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"]}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label>Surname:</label>
                                        </Grid>
                                        <Grid item xs={9} className="left">
                                            {user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"]}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label>Email Address:</label>
                                        </Grid>
                                        <Grid item xs={9} className="left">
                                            {user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"]}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label>GC Account ID:</label>
                                        </Grid>
                                        <Grid item xs={9} className="left">
                                            {user["http://schemas.ccl.org/accounts/claims/account/id"]}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label>GC User ID:</label>
                                        </Grid>
                                        <Grid item xs={9} className="left">
                                            {user["http://schemas.ccl.org/accounts/claims/user/id"]}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label>Enabled:</label>
                                        </Grid>
                                        <Grid item xs={9} className="left">
                                            {user["http://schemas.ccl.org/accounts/claims/user/enabled"]}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label>Tenant ID:</label>
                                        </Grid>
                                        <Grid item xs={9} className="left">
                                            {user["http://schemas.ccl.org/accounts/claims/tenant/id"]}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label>Groups:</label>
                                        </Grid>
                                        <Grid item xs={9} className="left">
                                            {user["http://schemas.ccl.org/accounts/claims/group/ids"]}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Typography>
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default UserInfo;
