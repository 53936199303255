import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardContent, Typography, Stack, useTheme, Box, Toolbar, Button, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from "../../../store";
import { DisplayQuestionGroupComponent } from "../questions/DisplayQuestionGroupComponent";
import { SurveySectionProps } from "./SurveySectionComponent";
import InfoIcon from '@mui/icons-material/Info';
import "./SurveySection.scss";
import { isHTML } from "../../../hooks/common";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import components from "../../../theme/components";
import { FormattedPhrase } from "../../../context/FormattedPhrase";

export const SectionCard: React.FC<SurveySectionProps> = ({
	centerQuestions,
}) => {
	const { currentSection } = useSelector((state: RootState) => state.user);
	const { currentGroupIndex, tabs } = useSelector(
		(state: RootState) => state.navigation
	);
	const theme = useTheme();
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<Card className={
			tabs.length ? "question-card extra-padding" : "question-card"
		}>
			<CardContent>
				<Stack direction="row" mb={4}>
					{currentSection.heading && (
						<Typography
							gutterBottom
							variant="subtitle2"
							fontWeight={700}
							component="div"
							className="p-3 question-title"
							sx={{
								pl: 7,
								pr: 5,
								pt: 5,
								pb: currentSection?.subheadings !== null ? 3 : 0,
							}}
						>
							{currentSection.heading}
						</Typography>
					)}
					{currentSection.information &&
						<InfoIcon
							sx={{
								color: theme.palette.primary.main,
								width: 22,
								height: 22,
								marginY: 3,
								cursor: "pointer",
							}}
							onClick={() => {setModalOpen(!modalOpen)}}
						/>
					}
				</Stack>
				<Fragment>
					{currentSection.information &&
						<Modal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
						>
							<Box sx={[components.MuiModal]}>
								<Toolbar sx={{ justifyContent: "flex-end" }}>
									<Button onClick={() => setModalOpen(false)}>
										<CloseIcon/>
									</Button>
								</Toolbar>
								{currentSection.information.map((item, index) => {
									return(
									<Box sx={{ minHeight: item.length > 75 ? "25%" : "15%", pb: 5 }} key={index}>
										<Typography
											gutterBottom
											variant="h5"
											component="div"
											className="p-3 text-left question-title"
											pb={2}
										>
											<ReactMarkdown rehypePlugins={[rehypeRaw]}>
												{item}
											</ReactMarkdown>
										</Typography>
									</Box>
									);
								})}
							</Box>
						</Modal>
					}
					{currentSection?.subheadings?.length > 0 && (
						<p className="question-subtitle" style={{ paddingLeft: "10px" }}>
							{currentSection.subheadings?.map((item, index) => {
								return (
									<Stack key={index} mt={item.length < 300 ? -10 : 20}>
										<label style={{ paddingLeft: 20, paddingTop: item.length < 200 ? 0 : 15, marginBottom: item.length < 200 ? -20 : 0}}>
											<ReactMarkdown rehypePlugins={[rehypeRaw]}>
												{item}
											</ReactMarkdown>
										</label>
									</Stack>
								);
							})}
						</p>
					)}
					<DisplayQuestionGroupComponent
						questionGroup={currentSection?.questionGroups[currentGroupIndex]}
						centerQuestions={centerQuestions}
					/>
				</Fragment>
			</CardContent>
		</Card>
	);
};
