import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	Grid,
	FormControlLabel,
	Radio,
	InputLabel,
	Checkbox,
	Typography,
	Stack,
	Button,
} from "@mui/material";
import { useTheme } from "@mui/material";

import { saveItemResponse } from "../../../services/apiService";
import { RootState } from "../../../store";
import {
	NavigationModes,
	Question,
	QuestionGroup,
	ScaleDisplayModes,
} from "../../../@types/surveys.d";

import { setActiveAnswers, setLoading } from "../../../store/User/UserSlice";
import Loading from "../../chrome/Loading";
import { gotoNext } from "../store/NavigationState";
import { FormattedPhrase } from "../../../context/FormattedPhrase";
import { setDefaultCount } from "../../../store/Navigation/NavSlice";
import { useTranslationContext } from "../../../context/TranslationContext";
import { AlertModal } from "../../shared/alertModal";

type QuestionGroupProps = {
	question: Question;
	questionGroup: QuestionGroup;
};

export const DisplayQuestionGroupScale: React.FC<QuestionGroupProps> = ({
	question,
	questionGroup,
}) => {
	const dispatch = useDispatch();

	const [selectedAnswer, setSelectedAnswer] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { getFormattedPhrase } = useTranslationContext();
	const { activeSurvey, activeAnswers, currentSection, instrumentId, localSurveyLanguage } =
		useSelector((state: RootState) => state.user);
	const { scaleDisplayMode } = useSelector(
		(state: RootState) => state.navigation
	);
	const theme = useTheme();
	const [alertOpen, setAlertOpen] = useState(false);

	const {
		currentGroupIndex,
		currentNavigationMode,
		currentQuestionIndex,
		maximumPrevious,
		numPrevious,
	} = useSelector((state: RootState) => state.navigation);

	const getSelectedAnswer = (formItemId: number) => {
		let givenAnswers = activeAnswers.filter((a) => a.formItemId === formItemId);
		if (givenAnswers && givenAnswers.length > 0) {
			return givenAnswers[givenAnswers.length - 1].answer;
		}
		return "";
	};

	const handleResponse = async (
		itemId: number,
		sequenceNum: number,
		response: string
	) => {
		dispatch(setLoading(true));
		setSelectedAnswer(response);

		try {
			await saveItemResponse(
				activeSurvey.surveyDocument.id,
				instrumentId,
				itemId,
				sequenceNum,
				response
			).then((res) => {
				if (res?.answers) {
					let newAnswer = {
						...res.answers[0],
						surveyDocumentId: activeSurvey.surveyDocument.id,
					};
					let updated = [...activeAnswers, newAnswer];
					dispatch(setActiveAnswers(JSON.stringify(updated)));

					// Mark as answered and navigate only after saving the response.
					const domQuesById = document.getElementById(
						`question_${question.formItemId}`
					);
					
					if (domQuesById) {
						domQuesById.classList.add("answered");
						if (currentNavigationMode === NavigationModes.ByQuestion) {
							gotoNext(
								currentNavigationMode,
								false,
								currentQuestionIndex,
								currentGroupIndex,
								currentSection,
								activeSurvey,
								activeAnswers,
								numPrevious,
								maximumPrevious,
								dispatch
							);
						}
					}
				}
				else {
					// in case the saving fails for some reason, we'll just show the last response for that question
					// get the last response
					var answer = getSelectedAnswer(question.formItemId);
					setSelectedAnswer(answer);
					// show the same checked count as previous as the response was not changed
					dispatch(setDefaultCount(true));
					setAlertOpen(true);
				}
			});
		} catch (error) {
			// in case the saving fails for some reason, we'll just show the last response for that question
			console.error(error);
			// get the last response
			var answer = getSelectedAnswer(question.formItemId);
			setSelectedAnswer(answer);
			// show the same checked count as previous as the response was not changed
			dispatch(setDefaultCount(true));
			setAlertOpen(true);
		} finally {
			dispatch(setLoading(false));
		}
	};

	// //Fix for 76046
	// useEffect(() => {
		// if (question?.answer) {
		// 	setSelectedAnswer(question?.answer);
		// 	console.log(question)
		// } else {
		// 	let givenAnswer = activeAnswers.find(
		// 		(a) => a.formItemId === question.formItemId
		// 	);
		// 	if (givenAnswer) {
		// 		setSelectedAnswer(givenAnswer.answer);
		// 	} else {
		// 		setSelectedAnswer("");
		// 	}
		// }
	// }, [question]);

	useEffect(() => {
		setSelectedAnswer("");
		if (activeAnswers && question) {
			activeAnswers.map((ans) => {
				if (ans.formItemId === question.formItemId) {
					setSelectedAnswer(ans.answer);
				}
			});
		}
	}, [activeAnswers, currentQuestionIndex, question]);

	const getSection = (sectionIndex: number) => {
		if (
			sectionIndex >= 0 &&
			sectionIndex < activeSurvey.surveyDocument.survey.surveySections.length
		) {
			return activeSurvey.surveyDocument.survey.surveySections[sectionIndex];
		} else {
			return activeSurvey.surveyDocument.survey.surveySections[0];
		}
	};

	const renderNotApplicableButton = (naButton: any, verticalList: boolean) => {
		return (
			<Grid
				container
				mt={1}
				xs={12}
				sx={{ justifyContent: "flex-start" }}
			>
				<Grid
					item
				>
					<FormControlLabel
						value={naButton.buttonValue}
						control={
							<Checkbox
								name={`radio-group-${question.formItemId}`}
								checked={selectedAnswer === naButton.buttonValue}
								onChange={(e) =>
									handleResponse &&
									handleResponse(
										question.formItemId,
										question.formItemSequenceNumber,
										naButton.buttonValue
									)
								}
							/>
						}
						label={
							naButton.meaning
								? naButton.buttonText + " / " + naButton.meaning
								: naButton.buttonText
						}
					/>
				</Grid>
			</Grid>
		);
	};

	const renderHorizontalScales = () => {
		return (
			<Fragment>
				<Grid item xs={12} sx={{ borderWidth: 1, borderColor: "#000" }}>
					<InputLabel sx={{ pb: 2, fontSize: "0.75rem", textAlign: "center" }}>
						<FormattedPhrase id="surveys.radioButton.desc1" language={localSurveyLanguage} />
					</InputLabel>
					<Stack flexDirection={"row"} flex={1} justifyContent={"space-evenly"}>
						{questionGroup?.surveyScale?.buttons?.map((item, index) => {
							return (
								<Stack alignItems={"center"} key={index}>
									<Button
										sx={[
											{border: "1px solid lightgray", borderRadius: "100%", width: 70, height: 70, justifyContent: "center", paddingBottom: 3, boxShadow: "rgba(50, 50, 93, 0.5) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px"},
											{":hover": {backgroundColor: theme.palette.secondary.main, color: theme.palette.background.default}},
											(selectedAnswer === item.buttonValue) 
												? {backgroundColor: theme.palette.primary.main, color: theme.palette.background.default} 
												: {backgroundColor: theme.palette.background.default, color: theme.palette.primary.main}
										]}
										name={`radio-group-${question.formItemId}`}
										onClick={(e) =>
											handleResponse &&
											handleResponse(
												question.formItemId,
												question.formItemSequenceNumber,
												item.buttonValue
											)
										}
									>
										<Typography>
											{item.buttonText}
										</Typography>
									</Button>
									<Typography marginTop={5}>
										{item.meaning}
									</Typography>
								</Stack>
							);
						})}
					</Stack>
					{questionGroup?.surveyScale?.notApplicableButton ? (
						renderNotApplicableButton(
							questionGroup?.surveyScale?.notApplicableButton,
							false
						)
					) : (
						<Fragment />
					)}
				</Grid>
			</Fragment>
		);
	};

	const renderVerticalScales = () => {
		return (
			<Fragment>
				<Grid
					item
					xs={12}
					sx={{
						flex: 1,
						display: "block",
					}}
				>
					<InputLabel sx={{ pb: 2, fontSize: "0.75rem" }}>
						<FormattedPhrase id="surveys.radioButton.desc1" language={localSurveyLanguage} />
					</InputLabel>
					{questionGroup?.surveyScale?.buttons?.map((item, index) => {
						let w = 100 / questionGroup?.surveyScale?.buttons.length;
						let width = `${w * (window.innerWidth > 1024 ? 0.75 : (window.innerWidth * 0.0007))}%`;

						return (
							<Stack key={index}>
								<FormControlLabel
									value={item.buttonValue}
									control={
										<Radio
											name={`radio-group-${question.formItemId}`}
											checked={selectedAnswer === item.buttonValue}
											onChange={(e) =>
												handleResponse &&
												handleResponse(
													question.formItemId,
													question.formItemSequenceNumber,
													item.buttonValue
												)
											}
										/>
									}
									sx={{
										justifyContent: "flex-start",
										alignContent: "flex-start",
										textAlign: "center",
										verticalAlign: "top",
									}}
									label={item.meaning === "" ? item.buttonText : item.meaning}
									labelPlacement="end"
								/>
							</Stack>
						);
					})}
					{questionGroup?.surveyScale?.notApplicableButton ? (
						renderNotApplicableButton(
							questionGroup?.surveyScale?.notApplicableButton,
							false
						)
					) : (
						//false shouldn't be passed in the above case, since UI for all scales is horizontal, it
						//has currently been done this way
						<Fragment />
					)}
				</Grid>
			</Fragment>
		);
	};

	return (
		<Grid container className="question-scale">
			<AlertModal text={getFormattedPhrase("error.savingResponse")} onClose={() => setAlertOpen(false)} isOpen={alertOpen} />
			{isLoading && <Loading />}
			{scaleDisplayMode === ScaleDisplayModes.AlignHorizontally ? (
				<Fragment>{renderHorizontalScales()}</Fragment>
			) : (
				<Fragment>{renderVerticalScales()}</Fragment>
			)}
		</Grid>
	);
};
