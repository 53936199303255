import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	Typography,
	useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { RootState } from "../../store";
import { AddRaterRequest, Rater, RaterStatus } from "../../@types/raters.d";
import {
	addRatersRequest,
	getActiveLanguages,
} from "../../services/apiService";
import { useNavigate } from "react-router-dom-v5-compat";
import { setInstrumentId, setParticipantId } from "../../store/User/UserSlice";
import { RaterInputRow } from "./RaterInputRow";
import { Language } from "../../@types/language.d";
import { RaterType } from "../../@types/raters.d";
import { FormattedPhrase } from "../../context/FormattedPhrase";
import { useTranslationContext } from "../../context/TranslationContext";
import { isValidEmail } from "../../utils/common";
import { Loading } from "../../components";

type AddAssessmentRatersProps = {
	pid: number;
	id: number;
	raterStatus: RaterStatus;
	raterType: RaterType;
	onReload?: () => void;
};

export const AddAssessmentRaters: React.FC<AddAssessmentRatersProps> = ({
	pid,
	id,
	raterStatus,
	raterType,
	onReload,
}) => {
	const dispatch = useDispatch();

	const { getFormattedPhrase } = useTranslationContext();

	const [busy, setBusy] = useState(false);

	const navigate = useNavigate();

	const theme = useTheme();

	const minRaters: number = raterStatus.raterRequiredCount
		? raterStatus.raterRequiredCount
		: raterStatus.raterRecommendedCount
		? raterStatus.raterRecommendedCount
		: 1;

	const [languages, setLanguages] = useState<Array<Language>>([]);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [raterExists, setRaterExists] = useState(false);
	const { instrumentId, instrumentRaterResponse, participantId, profileResponse } = useSelector(
		(state: RootState) => state.user
	);

	const [pendingRaters, setPendingRaters] = useState<Array<Rater>>([]);

	const asyncLoadingTasks = async () => {
		let pr: Array<Rater> = [...pendingRaters];
		for (var i = pendingRaters.length; i < minRaters; ++i) {
			pr.push({ email: "", firstName: "", lastName: "" } as Rater);
		}
		setPendingRaters(pr);

		if (!instrumentId || !participantId) {
			if (id && pid) {
				dispatch(setInstrumentId(id));
				dispatch(setParticipantId(pid));
			} else {
				navigate("/");
			}
		}

		if (instrumentId) {
			// WAS DISABLED FOR 77530
			let langs = await getActiveLanguages(instrumentId, 1);
			setLanguages(langs.languages);
			// DISABLING FOR 77945
			// setLanguages(profileResponse?.applicationLanguages);
		}
	};

	const addRaterRow = () => {
		let pr = [...pendingRaters, {} as Rater];
		setPendingRaters(pr);
	};

	const addRaters = async () => {
		setBusy(true);
		let arrAddRequests: Array<AddRaterRequest> = [];
		pendingRaters.forEach((rater: Rater) => {
			if (
				isValidEmail(rater.email) &&
				rater.firstName !== "" &&
				rater.lastName !== "" &&
				(JSON.stringify(rater.language) !== "{}" || raterExists)
			) {
				arrAddRequests.push({
					email: rater.email,
					firstName: rater.firstName,
					lastName: rater.lastName,
					instrumentId: instrumentId,
					participantId: participantId,
					languageId: rater.language.id,
					raterTypeId: raterType.id,
				});
			}
		});

		if (arrAddRequests && arrAddRequests.length > 0) {
			let addResponse = await addRatersRequest(arrAddRequests);
			setBusy(false);
			switch (addResponse.status) {
				case 0: // Success,
					if (onReload) onReload();

					let pr: Array<Rater> = [];
					pr.push({ email: "", firstName: "", lastName: "" } as Rater);
					setPendingRaters(pr);
					setOpen(false);
					break;
				case -1: // Self error, show message
					setErrorMessage(
						getFormattedPhrase("raterManagement.addRater.error.self")
					);
					return;
				case -2: // Duplicate error, show message
					setErrorMessage(
						getFormattedPhrase(
							"raterManagement.addRater.error.duplicate"
						).replace("{{email}}", addResponse.email)
					);
					return;
				default:
					return;
			}
		}

		else {
			setErrorMessage(
				getFormattedPhrase("raterManagement.addRater.error.invalid")
			);
			setBusy(false);
		}
	};

	const updateRaterRow = async (index: number, data: string, rater: Rater) => {
		let pr = [...pendingRaters];

		if (data === "firstname") {
			pr[index] = {
				...rater,
				firstName: rater?.firstName ?? "",
			};
		} else if (data === "lastname") {
			pr[index] = {
				...rater,
				lastName: rater?.lastName ?? "",
			};
		} else if (data === "email") {
			pr[index] = {
				...rater,
				email: rater?.email ?? "",
			};
		} else if (data === "language") {
			pr[index] = {
				...rater,
				language: rater?.language ?? ({} as Language),
			};
		} else if (data === "all") {
			pr[index] = {
				...rater,
				email: rater?.email ?? "",
				firstName: rater?.firstName ?? "",
				lastName: rater?.lastName ?? "",
				language: rater?.language ?? ({} as Language),
				raterTypeId: raterType.id,
			};
		}

		setPendingRaters(pr);
		setErrorMessage("");
	};

	const deleteRaterRow = async (index: number) => {
		let pr = [...pendingRaters].slice();
		pr.splice(index, 1);
		pr.push({ email: "", firstName: "", lastName: "" } as Rater);
		setPendingRaters(pr);
		setErrorMessage("");
	};

	useEffect(() => {
		asyncLoadingTasks();
	}, []);

	useEffect(() => {
		if (pendingRaters?.length) {
			// console.log("pendingRaters", pendingRaters);
			let allValid = true; // assume so

			pendingRaters.forEach((rater: Rater, index: number) => {
				allValid =
					rater.firstName !== "" &&
					rater.lastName !== "" &&
					rater.language?.id !== 0;
			});
		}
	}, [pendingRaters]);

	const [open, setOpen] = useState(
		!(raterStatus.raterInvitedCount >= raterStatus.raterRequiredCount)
	);

	const getRaterTitle = (id: number) => {
		switch(id) {
			case 2: return getFormattedPhrase("raterManagement.type.immediateBoss");
			break;
			case 3: return getFormattedPhrase("raterManagement.type.superior");
			break;
			case 4: return getFormattedPhrase("raterManagement.type.peer");
			break;
			case 5: return getFormattedPhrase("raterManagement.type.directReport");
			break;
			case 13: return getFormattedPhrase("raterManagement.type.boardMember");
			break;
		}
		return getFormattedPhrase("raterManagement.type.other");
	}

	return (
		<>
			{busy && <Loading />}
			<Accordion expanded={open} onChange={() => setOpen(!open)}>
				<AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
					<Typography
						variant={"h6"}
						fontWeight={700}
						color={
							raterStatus.raterInvitedCount >= raterStatus.raterRequiredCount
								? "black"
								: theme.palette.error.main
						}
						ml={-1}
					>
						<FormattedPhrase
							id="raterManagement.addRater.addRaterName"
							values={{ raterName: getRaterTitle(raterStatus.raterType?.id) }}
						/>
						{/* {raterStatus.raterInvitedCount >= raterStatus.raterRequiredCount ? (
							<></>
						) : (
							" *"
						)} */}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Card
						sx={{
							ml: -5,
							mt: -4
						}}
					>
						<CardContent
							sx={{ p: 0, m: 0, "&:last-child": { paddingBottom: 0 } }}
						>
							{raterStatus && (
								<Grid container spacing={0} rowSpacing={0}>
									<Grid item xs={12}>
										{pendingRaters?.length > 0 &&
											pendingRaters.map((rater: Rater, index: number) => (
												<Box
													key={index}
													sx={{
														p: 2,
														borderBottomWidth:
															index < pendingRaters.length - 1 ? 1 : 0,
														borderBottomColor: "lightgray",
														borderBottomStyle: "solid",
													}}
												>
													<RaterInputRow
														index={index}
														rater={rater}
														languages={languages}
														onUpdateRater={(index, data, rater) =>
															updateRaterRow(index, data, rater)
														}
														onDeleteRater={(index) => deleteRaterRow(index)}
														raterAlreadyExists={(flag) => setRaterExists(flag)}
													/>
												</Box>
											))}
									</Grid>
									{errorMessage && (
										<Grid item xs={12} sx={{ m: 3 }}>
											<Typography variant="h6" color={theme.palette.error.main}>
												{errorMessage}
											</Typography>
										</Grid>
									)}
								</Grid>
							)}
						</CardContent>
					</Card>
					<Grid container my={5}>
						<Grid item xs={12} sx={{ textAlign: "right" }}>
							<Button
								variant="outlined"
								sx={{
									borderRadius: 10,
									mr: 5,
									py: 1,
									px: 4
								}}
								onClick={() => addRaterRow()}
							>
								<Typography color={theme.palette.secondary.main} fontSize={14} fontWeight={400}>
									{getFormattedPhrase("raterManagement.addRater.add") + " " + getFormattedPhrase("raterManagement.addRater.rater")}
								</Typography>
								<AddIcon
									sx={{ color: theme.palette.secondary.main, ml: 2, width: 20, height: 20 }}
								/>
							</Button>
							<Button
								variant="outlined"
								sx={{
									borderRadius: 10,
									"&:disabled": {
										color: theme.palette.secondary.main,
										opacity: 0.5,
									},
									p: 1
								}}
								onClick={addRaters}
							>
								<Typography color={theme.palette.secondary.main} fontSize={14} fontWeight={400}>
									{getFormattedPhrase("raterManagement.addRater.invite")}
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>
	);
};
