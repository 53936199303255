import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Typography, Stack, useTheme } from "@mui/material";
import {
	setAllowNext,
	setAllowPrevious,
	setCanSubmit,
	setGoBack,
	setQuestionNumber,
	setReviewUnanswered,
	setSectionId,
	setTabs,
} from "../../../store/Navigation/NavSlice";
import { RootState } from "../../../store";
import { SurveySectionProps } from "./SurveySectionComponent";

import {
	buildQuestionList,
	getSurveyReviewCards,
	isAnswered,
} from "../store/SurveyState";
import {
	SurveyDocumentStateModel,
	Question,
	SectionReviewCard,
	SurveySection,
	NavigationModes,
} from "../../../@types/surveys.d";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./SurveySection.scss";
import InfoIcon from '@mui/icons-material/Info';
import { FormattedPhrase } from "../../../context/FormattedPhrase";
import { useTranslationContext } from "../../../context/TranslationContext";
import { SurveySubmit } from "../SurveySubmit";
import { fetchSurveyAnswers } from "../../../store/User/UserSlice";
import { useAuth0 } from "@auth0/auth0-react";

export const SurveyReviewQuestionsComponent: React.FC<SurveySectionProps> =
	({}) => {
		const dispatch = useDispatch();
		const theme = useTheme();
		const { getFormattedPhrase } = useTranslationContext();
		const { activeSurvey, activeAnswers, currentSection, instrumentId, questionList, localSurveyLanguage } =
			useSelector((state: RootState) => state.user);
		const { canSubmit, currentNavigationMode } = useSelector(
			(state: RootState) => state.navigation
		);
		const { user } = useAuth0();
		const [stateModel, setStateModel] = useState<SurveyDocumentStateModel>(
			{} as SurveyDocumentStateModel
		);
		const [sectionReviewCard, setSectionReviewCard] = useState<
			Array<SectionReviewCard>
		>([] as Array<SectionReviewCard>);

		const [localAnswers, setLocalAnswers] = useState<any>();

		const asyncActions = async () => {
			dispatch(setTabs([]));
			dispatch(setQuestionNumber(0));
			dispatch(setSectionId(""));
			dispatch(setAllowNext(false));
			dispatch(setAllowPrevious(true));
			let sectionCards: any = [];

			if (currentSection) {
				stateModel.surveyDocument = activeSurvey.surveyDocument;
				stateModel.surveySections =
					activeSurvey.surveyDocument.survey.surveySections;
				stateModel.surveyAnswers = localAnswers;
				stateModel.questionList = buildQuestionList(
					activeSurvey?.surveyDocument.survey.surveySections,
					localAnswers
				);

				// get the unanswered questions for review
				let reviewCards: any = getSurveyReviewCards(stateModel);
				let allSectionsValid = true; // Assume so
				for (var i = 0; i < reviewCards.length; i++) {
					let currentReviewSection =
						activeSurvey.surveyDocument.survey.surveySections.find(
							(s) => s.sectionId === reviewCards[i].sectionId
						) as SurveySection;

					let filteredArr = reviewCards[i].unansweredQuestions.filter(
						(item, index) => {
							return reviewCards[i].unansweredQuestions.indexOf(item) === index;
						}
					);
					filteredArr = filteredArr.filter((a) => a !== undefined);

					const filteredNotObserved = reviewCards[
						i
					].notObservedQuestions.filter((item, index) => {
						return reviewCards[i].notObservedQuestions.indexOf(item) === index;
					});

					if (
						reviewCards[i].component === "SurveyCompetenciesQuestionsComponent"
					) {
						if (reviewCards[i].validationRule?.conditions?.all) {
							let requiredAnswers =
								reviewCards[i].validationRule?.conditions?.all[0].value;
							// Do not show the unanswered/unchecked items when the required number of answers are checked
							if (reviewCards[i].numberAnswered >= requiredAnswers) {
								filteredArr = [];
							}
						}
					}

					sectionCards.push({
						sectionId: reviewCards[i].sectionId,
						heading: reviewCards[i].heading,
						notObservedAnswered: reviewCards[i].notObservedAnswered,
						unansweredQuestions: filteredArr as Array<Question>,
						notObservedQuestions: filteredNotObserved as Array<Question>,
						optional: reviewCards[i].optional,
						validationRule: reviewCards[i].validationRule!,
						numberAnswered: reviewCards[i].numberAnswered,
						component: reviewCards[i].component,
					});

					if (currentNavigationMode !== NavigationModes.ByQuestion) {
						let validSection = await isAnswered(
							activeSurvey.surveyDocument.survey.instrumentId,
							currentReviewSection,
							questionList,
							localAnswers,
							reviewCards[i].validationRule
						);
						allSectionsValid = allSectionsValid && validSection;

						// special check for validators and questions that are required in some cases
						if (reviewCards[i].component !== "SurveyCompetenciesQuestionsComponent" && reviewCards[i].unansweredQuestions.length > 0 && instrumentId !== 2946) {
							if (instrumentId === 1912 && (reviewCards[i].heading === "Research Questions" && reviewCards[i].optional)) {
								allSectionsValid = allSectionsValid && true;
							}
							else {
								// if there is a conditional validator and there are unanswered questions
								reviewCards[i].unansweredQuestions.map(x => {
									if (x?.conditionalValidators !== null) {
										filteredArr = [];
										allSectionsValid = false;
									}
									else {
										// if there is a dependent option that is required based on the parent option
										if (x?.options?.length > 0) {
											x.options.map(y => {
												if (y.dependentOption?.optional === false && y.answer === "1") {
													allSectionsValid = false;
												}
											})
										}
										else {
											// if there is any required question that is unanswered, track that here
											if (!x.optional && x.answer === null) {
												allSectionsValid = false;
											}
										}
									}
								})
							}
						}
					} else {
						// validation for surveys by question
						let reviewableSections =
							activeSurvey.surveyDocument.survey.surveySections.filter(
								(surveySection: SurveySection) =>
									surveySection.reviewable &&
									surveySection.questionGroups &&
									surveySection.sectionId !== currentSection.sectionId
							);

						reviewableSections.map(async (surveySection: SurveySection) =>
							isAnswered(
								activeSurvey.surveyDocument.survey.instrumentId,
								surveySection,
								buildQuestionList(
									activeSurvey.surveyDocument.survey.surveySections,
									localAnswers
								),
								localAnswers
							).then((res) => {
								dispatch(setCanSubmit(res));
							})
						);
					}
				}
				setSectionReviewCard(sectionCards);
				dispatch(setCanSubmit(allSectionsValid));
			}
		};

		useEffect(() => {
			let requestData = {
				user: user,
				instrumentId: instrumentId,
				participantId: activeSurvey.surveyDocument.participantId,
				languageId: activeSurvey.surveyDocument.survey.languageId,
				surveyTypeId: activeSurvey.surveyDocument.survey.surveyTypeId,
			};
			dispatch(fetchSurveyAnswers(requestData));
		}, [currentSection]);

		useEffect(() => {
			setLocalAnswers(activeAnswers);
		}, [activeAnswers]);

		useEffect(() => {
			if (localAnswers) {
				asyncActions();
			}
		},[localAnswers])

		useEffect(() => {}, [sectionReviewCard]);

		useEffect(() => {}, [canSubmit]);

		const sectionCardClicked = (sectionId, formItemId) => {
			dispatch(setGoBack(true));
			let itemId = formItemId;
			// Temporary fix for Real Survey navigation
			if (instrumentId === 2948) {
				// check if the question number ends with an alphabet
				itemId = formItemId.match("^.*[A-Za-z]$") ? (formItemId.slice(0, -1)) : formItemId
			}
			// to scroll to the correct question
			dispatch(
				setReviewUnanswered({
					sectionId: sectionId,
					formItemId: itemId
				})
			);
		};

		if (localAnswers) {
			return (
				<Fragment>
					<Box
						sx={{ flexGrow: 1, ml: 3, mr: 3, mb: 8 }}
						className="review-info"
					>
						<Grid container paddingY={2}>
							<Grid item md={12}>
								<Fragment>
									{/* DISABLING AS PART OF 73399 
									{currentSection?.information?.map((item, index) => {
										return (
											<Fragment key={index}> */}
												<Stack direction="row" paddingX={3}>
													<InfoIcon
														sx={{ mr: 3, color: theme.palette.primary.main }}
													/>
													<Typography variant="h6" fontWeight={400}>
														{getFormattedPhrase("surveys.submitReview", null, localSurveyLanguage)}
													</Typography>
												</Stack>
											{/* </Fragment>
										);
									})} */}
								</Fragment>
							</Grid>
						</Grid>
					</Box>

					{sectionReviewCard &&
						sectionReviewCard.map((section, index) => (
							<Fragment key={index}>
								{section.component != "SurveyReviewQuestionsComponent" ? (
									<Box
										sx={{ flexGrow: 1, ml: 3, mr: 3, mb: 8 }}
										className="question-card"
									>
										<Grid container spacing={1}>
											<Grid item md={12}>
												<Box className="section-card" key={index}>
													<Stack>
														{!section.optional &&
														section.unansweredQuestions?.length > 0 ? (
															<>
																<Stack direction="row" mt={-2}>
																	<Typography
																		sx={{ flex: 1 }}
																		variant="subtitle2"
																		fontWeight={700}
																	>
																		{section.heading}
																	</Typography>
																	{!canSubmit &&
																		currentSection?.warnings?.map(
																			(item, index) => {
																				return (
																					<Fragment key={index}>
																						<Stack direction="row">
																							<Typography variant="h6">
																								<FormattedPhrase id="surveys.incomplete" language={localSurveyLanguage} />
																							</Typography>
																							<InfoIcon
																								className="warnings"
																								sx={{ ml: 3, mt: -1 }}
																							/>
																						</Stack>
																					</Fragment>
																				);
																			}
																		)}
																</Stack>
																<Box
																	p={0}
																	sx={{
																		width: window.innerWidth - 400,
																		overflowWrap: "break-word",
																		flex: 1,
																	}}
																>
																	{section?.unansweredQuestions?.map(
																		(item, index) => {
																			return (
																				<Box key={index}>
																					<Typography
																						variant="subtitle1"
																						fontWeight={700}
																						marginY={3}
																						width={"70%"}
																					>
																						{item?.number === null
																							? item?.text ?? ""
																							: item.number +
																							". " +
																							(item?.text ?? "")}
																					</Typography>
																					<Grid
																						xs={12}
																						md={5}
																						p={2}
																						display={"flex"}
																						flexDirection={"row"}
																						justifyContent={"space-between"}
																						alignItems={"center"}
																						className="solid-box"
																					>
																						<Typography>
																							<FormattedPhrase id="surveys.review.incomplete.review" language={localSurveyLanguage} />
																						</Typography>
																						<Button
																							variant="outlined"
																							sx={{
																								borderRadius: 10,
																								marginLeft: 5,
																								height: 30,
																								whiteSpace: "nowrap",
																							}}
																							onClick={() =>
																								{
																									sectionCardClicked(
																										section.sectionId,
																										item?.number ??
																											item?.formItemSequenceNumber
																									)
																								}
																							}
																						>
																							<FormattedPhrase id="participant.button.update" language={localSurveyLanguage} />
																						</Button>
																					</Grid>
																				</Box>
																			);
																		}
																	)}
																</Box>
															</>
														) : (instrumentId === 2947) ? (
															<Grid container>
																<Grid xs={4} margin={'0 auto'} display={'flex'} justifyContent={'center'}>
																	<SurveySubmit valid={true} />
																</Grid>
															</Grid>
														) : (
															<Stack direction="row" mt={-3}>
																{
																	section.heading &&
																	<Typography
																		sx={{ flex: 1 }}
																		variant="h4"
																		fontWeight={900}
																	>
																		{section.heading}
																	</Typography>
																}
																<Stack direction="row">
																	<Typography
																		variant="h6"
																		sx={{ color: theme.palette.success.main }}
																	>
																		<FormattedPhrase id="surveys.complete" language={localSurveyLanguage} />
																	</Typography>
																	<CheckCircleIcon
																		sx={{
																			cursor: "default",
																			fontWeight: 500,
																			fontSize: "0.938rem",
																			borderRadius: "100px",
																			alignContent: "center",
																			height: 15,
																			width: 15,
																			mt: 0,
																			ml: 2,
																			borderWidth: 1,
																			color: "#4B7E37",
																		}}
																		className="btn btn-outline-primary"
																	/>
																</Stack>
															</Stack>
														)}
													</Stack>
												</Box>
											</Grid>
										</Grid>
									</Box>
								) : (
									<Fragment></Fragment>
								)}
							</Fragment>
						))}
				</Fragment>
			)
		}

		else return (<></>);
	};
