import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { RootState } from "../../store";

import { Language } from "../../@types/language.d";
import { Rater } from "../../@types/raters.d";
import { searchRatersByEmail } from "../../services/apiService";
import { useTheme } from "@mui/material/styles";
import { FormattedPhrase } from "../../context/FormattedPhrase";
import { Loading } from "../../components";
import { useTranslationContext } from "../../context/TranslationContext";
import { isValidEmail } from "../../utils/common";

type RaterInputRowProps = {
	index: number;
	rater: Rater;
	languages: Array<Language>;
	onUpdateRater?: (index: number, data: string, rater: Rater) => void;
	onDeleteRater?: (index: number) => void;
	raterAlreadyExists: (flag: boolean) => void;
};

export const RaterInputRow: React.FC<RaterInputRowProps> = ({
	index,
	rater,
	languages,
	onUpdateRater,
	onDeleteRater,
	raterAlreadyExists
}) => {
	const theme = useTheme();
	const { getFormattedPhrase } = useTranslationContext();
	const { selectedLanguage, profileResponse } = useSelector((state: RootState) => state.user);

	const [email, setEmail] = useState<string>("");
	const [validEmail, setValidEmail] = useState<boolean>(true);
	const [firstName, setFirstName] = useState<string>("");
	const [validFirstName, setValidFirstName] = useState<boolean>(true);
	const [lastName, setLastName] = useState<string>("");
	const [validLastName, setValidLastName] = useState<boolean>(true);
	const [selectedSurveyLanguage, setSelectedSurveyLanguage] = useState<number>(0);
	const [anchorElRater, setAnchorElRater] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorElRater);
	const [busy, setBusy] = useState(false);
	const labels = {
		firstName: getFormattedPhrase("myProfile.placeholder.firstName"),
		lastName: getFormattedPhrase("myProfile.placeholder.lastName"),
		email: getFormattedPhrase("raterManagement.addRater.placeholder.email"),
		prefAppLang: getFormattedPhrase(
			"myProfile.placeholder.preferredApplicationLanguage"
		),
	};
	const [raterAlreadyInRecords, setRaterAlreadyInRecords] = useState(false);
	const [raterLanguageAlreadyExists, setRaterLanguageAlreadyExists] = useState(false);

	const handleOpenRaterMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElRater(event.currentTarget);
	};
	const handleCloseRaterMenu = () => {
		setAnchorElRater(null);
	};

	const searchRaters = async (email: string) => {
		setBusy(true);
		let match = await searchRatersByEmail(email);
		if (match && match.individual) {
			setRaterAlreadyInRecords(true);
			raterAlreadyExists(true);
			if (match.individual.language && match.individual.language.id) {
				setRaterLanguageAlreadyExists(true);
			}
			let dirty = false;

			if (email !== match?.individual?.email) {
				setEmail(match?.individual?.email);
				dirty = true;
			}
			if (firstName !== match?.individual?.firstName) {
				setFirstName(match?.individual?.firstName);
				dirty = true;
			}
			if (lastName !== match?.individual?.lastName) {
				setLastName(match?.individual?.lastName);
				dirty = true;
			}
			let raterLanguage = match?.individual?.language?.id ?? 0;
			if (selectedSurveyLanguage != raterLanguage) {
				setSelectedSurveyLanguage(raterLanguage);
				dirty = true;
			}

			if (dirty && onUpdateRater) {
				let lang = languages.find((l) => l.id === selectedSurveyLanguage) ?? profileResponse.applicationLanguages.find((l) => l.languageId === raterLanguage);
				if (lang) {
					if (lang.languageId > lang.id) {
						lang = {...lang, id: lang.languageId}
					}
				}
				onUpdateRater(index, 'all', {
					email: match?.individual?.email,
					firstName: match?.individual?.firstName,
					lastName: match?.individual?.lastName,
					language: lang ?? ({} as Language),
				} as Rater);
			}
		}
		else {
			setRaterAlreadyInRecords(false);
			raterAlreadyExists(false);
			setRaterLanguageAlreadyExists(false);
			setFirstName("");
			setLastName("");
			setSelectedSurveyLanguage(0);
		}
		setBusy(false);
	};

	const updateRater = async (data: string) => {
		if (onUpdateRater) {
			if (!raterAlreadyInRecords || data === 'language') {
				let lang = languages.find((l) => l.id === selectedSurveyLanguage) ?? profileResponse.applicationLanguages.find((l) => l.languageId === selectedSurveyLanguage);
				if (lang) {
					if (lang.languageId > lang.id) {
						lang = {...lang, id: lang.languageId}
					}
				}
				onUpdateRater(index, data, {
					email: email,
					firstName: firstName,
					lastName: lastName,
					language: lang ?? ({} as Language),
				} as Rater);
				setBusy(false);
			}
		}
	};

	useEffect(() => {
		updateRater('language');
	}, [selectedSurveyLanguage]);

	useEffect(() => {
		if (rater) {
			if (email != rater?.email) {
				setEmail(rater?.email);
			}
			if (firstName != rater?.firstName) {
				setFirstName(rater?.firstName);
			}
			if (lastName != rater?.lastName) {
				setLastName(rater?.lastName);
			}
			let raterLanguage = rater?.language?.id ?? 0;
			if (raterLanguage != selectedSurveyLanguage) {
				setSelectedSurveyLanguage(raterLanguage);
			}
		}
	}, [rater]);

	useEffect(() => {
		if (isValidEmail(email)) {
			setValidEmail(true);
			updateRater('email');
		}
	}, [email]);

	useEffect(() => {
		if (firstName !== "") {
			setValidFirstName(true);
			updateRater('firstname');
		}
	}, [firstName]);

	useEffect(() => {
		if (lastName !== "") {
			setValidLastName(true);
			updateRater('lastname');
		}
	}, [lastName]);

	useEffect(() => {

	},[raterAlreadyInRecords])

	return (
		<>
			{busy && <Loading />}
			<Grid container key={index} alignItems={"center"}>
				<Grid item xs={3}>
					<TextField
						sx={{ width: "95%", m: 2, '& label': {
							transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
							left: 'inherit !important',
							right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
						},
						'& legend': {
							textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
						} }}
						size={"small"}
						value={email}
						error={!validEmail}
						placeholder={labels.email}
						onBlur={(e) => {
							if (isValidEmail(e.target.value)) {
								searchRaters(e.target.value);
							} else {
								setValidEmail(false);
							}
						}}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						sx={{ width: "95%", m: 2, '& label': {
							transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
							left: 'inherit !important',
							right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
						},
						'& legend': {
							textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
						} }}
						size={"small"}
						value={firstName}
						error={!validFirstName}
						placeholder={labels.firstName}
						onBlur={(e) => {
							setValidFirstName(e.target.value !== "");
						}}
						onChange={(e) => setFirstName(e.target.value)}
						disabled={raterAlreadyInRecords}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						sx={{ width: "95%", m: 2 }}
						size={"small"}
						value={lastName}
						error={!validLastName}
						placeholder={labels.lastName}
						onBlur={(e) => {
							setValidLastName(e.target.value !== "");
						}}
						onChange={(e) => setLastName(e.target.value)}
						disabled={raterAlreadyInRecords}
					/>
				</Grid>
				<Grid item xs={2.5}>
					{raterAlreadyInRecords
					?
						<TextField
							sx={{ width: "95%", m: 2 }}
							size={"small"}
							value={profileResponse?.applicationLanguages.find((l) => l.languageId === selectedSurveyLanguage)?.name}
							error={!validLastName}
							placeholder={labels.lastName}
							disabled={raterAlreadyInRecords}
						/>
					:
						<Select
							disabled={raterAlreadyInRecords}
							sx={{ width: "95%", m: 2, '& label': {
								transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
								left: 'inherit !important',
								right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
							},
							'& legend': {
								textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
							} }}
							size="small"
							value={selectedSurveyLanguage}
							placeholder={labels.prefAppLang}
							onChange={(e) => {
								let lang = languages.find(
									(l) => l.id === parseInt(e.target.value as string)
								);
								setSelectedSurveyLanguage(lang?.id ?? 0);
							}}
						>
							<MenuItem
								key={0}
								value={0}
								selected={selectedSurveyLanguage === 0 ? true : false}
							>
								<FormattedPhrase id="surveys.selectLanguage"/>
							</MenuItem>
							{languages.map((l: Language, index: number) => (
								<MenuItem
									key={index}
									value={l.id}
									selected={selectedSurveyLanguage === l.id}
								>
									{l.name}
								</MenuItem>
							))}
						</Select>
					}
				</Grid>
				<Grid item xs={0.5}>
					<IconButton onClick={handleOpenRaterMenu} color={"primary"}>
						<MoreHorizIcon />
					</IconButton>
				</Grid>

				<Menu
					anchorEl={anchorElRater}
					id="account-menu"
					open={open}
					onClose={handleCloseRaterMenu}
					onClick={handleCloseRaterMenu}
					PaperProps={{
						elevation: 0,
						sx: {
							overflow: "visible",
							filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
							mt: 3,
							ml: 6,
							height: 50,
							justifyContent: "center",
							"& .MuiAvatar-root": {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							"&:before": {
								content: '""',
								display: "block",
								position: "absolute",
								top: 10,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: "background.paper",
								transform: "translateY(-50%) rotate(45deg)",
								zIndex: 0,
							},
						},
					}}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				>
					<MenuItem
						sx={{
							color: theme.palette.text.primary,
							"&:hover": {
								backgroundColor: theme.palette.primary.contrastText,
							},
						}}
						onClick={() => {
							if (onDeleteRater) onDeleteRater(index);
						}}
					>
						<FormattedPhrase id={"raterManagement.deleteRater.title"} />
					</MenuItem>
				</Menu>
			</Grid>
		</>
	);
};
