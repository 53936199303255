import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid } from "@mui/material";

import { RootState } from "../../store";
import { setCurrentSection, setSurveyReopened } from "../../store/User/UserSlice";
import {
	setAllowNext,
	setAllowPrevious,
	setCurrentGroupIndex,
	setCurrentQuestionIndex,
	setCurrentTabIndex,
	setGoBack,
	setQuestionNumber,
	setReviewUnanswered,
	setSectionId,
} from "../../store/Navigation/NavSlice";

import { getIndex } from "../../services/apiService";
import { SurveySubmit } from "./SurveySubmit";

import {
	NavigationModes,
	SurveyDocumentStateModel,
	SurveySection,
} from "../../@types/surveys.d";

import { getTabs } from "./store/TabbedSectionState";
import { buildQuestionList, getSelectedAnswer } from "./store/SurveyState";
import {
	findNextUnansweredQuestion,
	getNextUnansweredSection,
	gotoNext,
	gotoPrevious,
} from "./store/NavigationState";

import "./SurveyNavigation.scss";
import { useTranslationContext } from "../../context/TranslationContext";

type SurveyNavigationProps = {};

export const SurveyNavigation: React.FC<SurveyNavigationProps> = ({}) => {
	const dispatch = useDispatch();

	const { activeSurvey, activeAnswers, currentSection, surveyReopened, instrumentId } =
		useSelector((state: RootState) => state.user);
	const {
		allowNext,
		allowPrevious,
		currentGroupIndex,
		currentNavigationMode,
		currentQuestionIndex,
		maximumPrevious,
		numPrevious,
		continueAtNextUnanswered,
		tabs,
		reviewUnanswered,
		questionNumber,
		sectionId,
	} = useSelector((state: RootState) => state.navigation);

	const [sectionIndex, setSectionIndex] = useState<number>(0);
	const [isValid, setIsValid] = useState<boolean>();
	const { getFormattedPhrase } = useTranslationContext();
	const [prevText, setPreviousText] = useState<string | null>(null);
	const [prevGroupText, setPreviousGroupText] = useState<string | null>(null);
	const [nextText, setNextText] = useState<string | null>(null);
	const [nextGroupText, setNextGroupText] = useState<string | null>(null);

	const ref_nextButton = useRef(null);

	useEffect(() => {
		if (activeSurvey?.surveyDocument?.survey?.surveySections) {
			if (activeSurvey?.surveyDocument?.surveyStatusId < 2) {
				// Always show the first section if the survey has not started, i.e. no answers yet.
				dispatch(
					setCurrentSection(
						activeSurvey?.surveyDocument?.survey?.surveySections[0]
					)
				);
			} else if (!currentSection?.sectionId) {
				// For About Me, take the user to the Overview section instead of Review screen
				if (activeSurvey?.surveyDocument?.survey?.instrumentId === 2946 && activeSurvey?.surveyDocument?.surveyStatusId === 4) {
					dispatch(setSurveyReopened(true));
					dispatch(
						setCurrentSection(
							activeSurvey?.surveyDocument?.survey?.surveySections[0]
						)
					);
					return;
				}
				let stateModel = {
					surveyDocument: activeSurvey.surveyDocument,
					surveySections: activeSurvey.surveyDocument.survey.surveySections,
					surveyAnswers: activeSurvey.surveyAnswers,
					questionList: buildQuestionList(
						activeSurvey.surveyDocument.survey.surveySections,
						activeSurvey.surveyAnswers
					),
				} as SurveyDocumentStateModel;

				let nextUnanswerdQuestion = findNextUnansweredQuestion(stateModel);
				if (nextUnanswerdQuestion) {
					let nextSection = getNextUnansweredSection(activeSurvey, stateModel);
					if (nextSection) {
						// Go to the section...
						dispatch(setCurrentSection(nextSection));
						if (nextUnanswerdQuestion.groupIndex > 0) {
							dispatch(setCurrentGroupIndex(nextUnanswerdQuestion.groupIndex));
						}
						if (nextUnanswerdQuestion.questionIndex > 0) {
							dispatch(
								setCurrentQuestionIndex(nextUnanswerdQuestion.questionIndex)
							);
							dispatch(
								setReviewUnanswered({
									sectionId: nextSection.sectionId,
									formItemId: nextUnanswerdQuestion.questionIndex
								})
							);
						}
					} else {
						// Set the first section as current
						dispatch(
							setCurrentSection(
								activeSurvey?.surveyDocument?.survey?.surveySections[0]
							)
						);
					}
				} else {
					// Set the first section as current
					dispatch(
						setCurrentSection(
							activeSurvey?.surveyDocument?.survey?.surveySections[0]
						)
					);
				}

				dispatch(setAllowPrevious(true));
				window.scrollTo(0, 0);
			} else {
				let index = getIndex(
					currentSection,
					activeSurvey?.surveyDocument.survey.surveySections,
					"sectionId"
				);
				if (index > -1) {
					dispatch(
						setCurrentSection(
							activeSurvey?.surveyDocument.survey.surveySections[index]
						)
					);
				}
			}
		}
	}, [activeSurvey]);

	useEffect(() => {
		if (
			currentSection &&
			activeSurvey?.surveyDocument?.survey?.surveySections
		) {
			let ndx = getIndex(
				currentSection,
				activeSurvey.surveyDocument.survey.surveySections,
				"sectionId"
			);
			setSectionIndex(ndx);
			dispatch(setAllowPrevious(ndx > 0));

			if (currentGroupIndex > -1) {
				if (currentNavigationMode === NavigationModes.ByQuestion) {
					//condition to prevent race
					if (currentSection.questionGroups && currentSection?.questionGroups?.length > 0){
						// check if the current question is the last one
						let lastQuestion = currentSection.questionGroups[currentGroupIndex].questions.length;
						// Fix for WB5P
						if (instrumentId === 2947) {
							if (currentQuestionIndex === lastQuestion) {
								// do not allow to go back from the last question
								dispatch(setAllowPrevious(false));
							}
							else {
								dispatch(setAllowPrevious(true));
							}
						}
						else if (currentQuestionIndex < lastQuestion) {
							// do not allow to go back from the last question
							dispatch(setAllowPrevious(false));
						}
					}
				}
			}

			if (currentSection.submitButtonText) {
				dispatch(setAllowNext(false));
			} else {
				// If this is per question navigation, we'll figure this out in another useState method
				if (currentNavigationMode === NavigationModes.ByQuestion) {
					if (currentSection.component === "SurveyWelcomeComponent") {
						dispatch(setAllowNext(true));
					}

					return;
				}

				// If this section is reviewable, we'll let them continue but validate in the review section later.
				if (currentSection.reviewable) {
					if (currentSection.component === "SurveyWelcomeComponent") {
						let surveyStart =
						currentSection.questionGroups.every((qg) =>
							qg.questions.every((q) => {
								let answers = activeAnswers.filter(
									(a) => a.formItemId === q.formItemId
								);
								if (answers.length === 0) {
									return q.optional;
								}

								let lastAnswer = answers[answers.length - 1];
								// If not optional and answered...
								if (!q.optional) {
									if (lastAnswer.answer == null || lastAnswer.answer === "") {
										return false;
									}
								}
								return true;
							})
						);
						dispatch(setAllowNext(surveyStart));
					}
					else {
						dispatch(setAllowNext(true));
					}
				} else {
					if (currentSection.questionGroups) {
						let allRequiredQuestionsAnswered =
							currentSection.questionGroups.every((qg) =>
								qg.questions.every((q) => {
									let answers = activeAnswers.filter(
										(a) => a.formItemId === q.formItemId
									);
									if (answers.length === 0) {
										return q.optional;
									}

									let lastAnswer = answers[answers.length - 1];
									// If not optional and answered...
									if (!q.optional) {
										if (lastAnswer.answer == null || lastAnswer.answer === "") {
											return false;
										}
									}
									return true;
								})
							);
						dispatch(setAllowNext(allRequiredQuestionsAnswered));
					} else {
						dispatch(
							setAllowNext(
								ndx <
									activeSurvey.surveyDocument.survey.surveySections.length - 1
							)
						);
					}
				}
			}

			// Check if valid
			checkIfValid();
		}
	}, [activeSurvey, activeAnswers, currentSection]);

	useEffect(() => {
		if (currentNavigationMode === NavigationModes.ByQuestion) {
			try {
				// Can continue only if this question is answered.
				let currentQuestion =
					currentSection.questionGroups[currentGroupIndex].questions[
						currentQuestionIndex
					];
				let answer = getSelectedAnswer(
					currentQuestion.formItemId,
					activeAnswers
				);
				let allow = answer !== null && answer !== "";
				dispatch(setAllowNext(allow));
			} catch (err) {
				// Let this one go. It was an indexing error because the data is not right, but we'll re-evaluate on the next change.
			}
		}
	}, [currentNavigationMode, currentGroupIndex, currentQuestionIndex]);

	useEffect(() => {
		setPreviousText(currentSection?.previousButtonText);
		setNextText(currentSection?.nextButtonText);

		let nextBtnText: string = currentSection?.nextGroupButtonText ?? "";
		let prevBtnText: string = currentSection?.previousGroupButtonText ?? "";

		if (
			tabs.length === 0 || // There are no tabs, so this is the only group.
			currentGroupIndex === tabs.length - 1 || // This is the last of the tabs.
			nextBtnText.startsWith("{{") // There is a token in the nextBtnText we'll need to parse.
		) {
			setNextGroupText(currentSection?.nextButtonText);
			// this is for Real Companion
			if (instrumentId === 2948) {
				// get the section before Review section
				let sectionInd = activeSurvey?.surveyDocument?.survey?.surveySections?.length - 3;
				let lastSection = activeSurvey?.surveyDocument?.survey?.surveySections[sectionInd];
				if (currentSection.sectionId === lastSection?.sectionId) {
					// This is available only in English
					setNextGroupText("Review");
				}
			}
		} else {
			setNextGroupText(currentSection?.nextGroupButtonText);
		}

		if (currentGroupIndex === 0 || prevBtnText.startsWith("{{")) {
			setPreviousGroupText(currentSection?.previousButtonText);
		} else {
			if (currentNavigationMode === NavigationModes.ByQuestion) {
				setPreviousGroupText(currentSection?.previousButtonText);
			}
			else {
				setPreviousGroupText(currentSection?.previousGroupButtonText);
			}
		}

		if (currentSection?.component === "SurveyReviewQuestionsComponent") {
			if (
				reviewUnanswered?.sectionId != "" &&
				reviewUnanswered?.formItemId > 0
			) {
				goToSelectedQuestion(
					reviewUnanswered?.sectionId,
					reviewUnanswered?.formItemId
				);
			}
		}
		if (
			questionNumber > 0 &&
			sectionId !== "" &&
			currentSection.sectionId !== sectionId &&
			currentGroupIndex < 0 // Added this because navigation got odd when starting some surveys from scratch
		) {
			goToSelectedQuestion(sectionId, questionNumber);
		}
	}, [currentSection, reviewUnanswered, currentGroupIndex, questionNumber, tabs]);

	useEffect(() => {
		// console.log("allowNext", allowNext);
		// console.log("allowPrevious", allowPrevious);
		// console.log("isValid", isValid);
	}, [allowNext, allowPrevious, isValid]);

	const goToSelectedQuestion = (
		sectionId: string,
		formItemSequenceNumber: number
	) => {
		let newSection = activeSurvey.surveyDocument.survey.surveySections.filter(
			(a) => {
				if (a.sectionId === sectionId) {
					return a;
				}
			}
		);

		let questionsList: any = [];
		newSection.map((x) => {
			x?.questionGroups?.map((a) => {
				return a.questions.map((b) => {
					questionsList.push(b);
				});
			});
		});

		let tabs = getTabs(newSection[0], questionsList);
		tabs.map((x) => {
			let index = x.tabText.trim().split(" -");

			//some surveys have their question groups named as parts, part 1 instead of 1-10 (without the question numbers)
			if (isNaN(index)) {
				let groupIndex = -1;
				for (let i=0; i<newSection[0]?.questionGroups?.length; i++) {
					for (let j=0; j<newSection[0].questionGroups[i].questions.length; j++) {
						//in these cases, the formItemSequence number matches with the question 'number'
						if (Number(newSection[0].questionGroups[i].questions[j].number) === Number(formItemSequenceNumber)) {
							groupIndex = i;
							break;
						}
					}
					if (groupIndex > -1) {
						break;
					}
				}
				dispatch(setCurrentGroupIndex(groupIndex > -1 ? groupIndex : 0));
				dispatch(setCurrentTabIndex(groupIndex > -1 ? groupIndex : 0));
			} else {
				let min = Number(index[0]);
				let max = Number(index[1]);

				if (formItemSequenceNumber >= min && formItemSequenceNumber <= max) {
					if (x.questionGroupIndexes.length > 1) {
						let noOfQues =
							Math.floor((max - min) / x.questionGroupIndexes.length) + 1;
						let index1 = min + noOfQues;
						let index2 = noOfQues + index1;
						let index3 = index2 + noOfQues;

						if (formItemSequenceNumber <= index1) {
							dispatch(setCurrentGroupIndex(x.questionGroupIndexes[0]));
							dispatch(setCurrentTabIndex(x.questionGroupIndexes[0]));
						} else if (
							formItemSequenceNumber >= index1 &&
							formItemSequenceNumber <= index2
						) {
							dispatch(setCurrentGroupIndex(x.questionGroupIndexes[1]));
							dispatch(setCurrentTabIndex(x.questionGroupIndexes[1]));
						} else if (
							formItemSequenceNumber >= index2 &&
							formItemSequenceNumber <= index3
						) {
							dispatch(setCurrentGroupIndex(x.questionGroupIndexes[2]));
							dispatch(setCurrentTabIndex(x.questionGroupIndexes[2]));
						}
					} else {
						dispatch(setCurrentGroupIndex(x.questionGroupIndexes[0]));
						dispatch(setCurrentTabIndex(x.questionGroupIndexes[0]));
					}
				}
			}
		});
		dispatch(setCurrentSection(newSection[0]));
		dispatch(setQuestionNumber(0));
		dispatch(setSectionId(""));
	};

	const onPrevious = async (evt: any) => {
		switch (currentNavigationMode) {
			case NavigationModes.ByQuestion:
				gotoPrevious(
					numPrevious,
					maximumPrevious,
					reviewUnanswered,
					currentNavigationMode,
					currentQuestionIndex,
					currentGroupIndex,
					currentSection,
					activeSurvey,
					dispatch
				);
				break;

			default:
				if (currentGroupIndex === 0) {
					dispatch(setCurrentGroupIndex(-1));
					dispatch(setCurrentQuestionIndex(-1));
					dispatch(setCurrentSection({} as SurveySection));
					dispatch(setGoBack(true));
				}
				gotoPrevious(
					numPrevious,
					maximumPrevious,
					reviewUnanswered,
					currentNavigationMode,
					currentQuestionIndex,
					currentGroupIndex,
					currentSection,
					activeSurvey,
					dispatch
				);
		}
	};

	const onNext = async (evt: any) => {
		gotoNext(
			currentNavigationMode,
			continueAtNextUnanswered,
			currentQuestionIndex,
			currentGroupIndex,
			currentSection,
			activeSurvey,
			activeAnswers,
			numPrevious,
			maximumPrevious,
			dispatch
		);
	};

	const checkIfValid = async () => {
		switch (currentNavigationMode) {
			case NavigationModes.ByQuestion:
				{
					if (
						currentGroupIndex > -1 &&
						currentQuestionIndex > -1 &&
						currentGroupIndex < currentSection?.questionGroups?.length &&
						currentQuestionIndex <
							currentSection?.questionGroups[currentGroupIndex].questions
								?.length
					) {
						let group = currentSection.questionGroups[currentGroupIndex];
						let question = group.questions[currentQuestionIndex];
						if (!question.optional && question.answer === "") {
							// Not valid
							setIsValid(false);
							return;
						}
					}
				}
				break;

			case NavigationModes.ByQuestionGroup:
				{
					if (
						currentGroupIndex > -1 &&
						currentSection?.questionGroups?.length > 0
					) {
						let group = currentSection.questionGroups[currentGroupIndex];
						for (let q = 0; q < group.questions?.length; ++q) {
							let question = group.questions[q];
							if (!question.optional && question.answer === "") {
								// Not valid
								setIsValid(false);
								return;
							}
						}
					}
				}
				break;

			case NavigationModes.BySection:
			default:
				{
					for (let g = 0; g < currentSection?.questionGroups?.length; ++g) {
						let group = currentSection.questionGroups[g];
						for (let q = 0; q < group.questions?.length; ++q) {
							let question = group.questions[q];
							if (!question.optional && question.answer === "") {
								// Not valid
								setIsValid(false);
								return;
							}
						}
					}
				}
				break;
		}

		setIsValid(true);
	};

	const renderButtons = () => {
		let prevButton: JSX.Element = <Fragment />;
		let nextButton: JSX.Element = <Fragment />;
		switch (currentNavigationMode) {
			case NavigationModes.ByQuestion:
			case NavigationModes.ByQuestionGroup:
				prevButton = (
					<Fragment>
						{(prevGroupText || prevText) && sectionIndex > 0 && (
							<Button
								variant="outlined"
								size="large"
								className={
									!allowPrevious || maximumPrevious === 0
										? "btn-style disabled"
										: "btn-style"
								}
								disabled={!allowPrevious || maximumPrevious === 0}
								onClick={(e) => onPrevious(e)}
								tabIndex={1}
								sx={{ m: 1, fontSize: "0.875rem" }}
							>
								{prevGroupText ?? prevText}
							</Button>
						)}
					</Fragment>
				);

				nextButton = (
					<Fragment>
						{currentSection?.component === "SurveyReviewQuestionsComponent" ||
						currentSection.submitButtonText ? (
							<Fragment>
								{currentSection.reviewable ? (
									<SurveySubmit valid={isValid as boolean} />
								) : (
									<SurveySubmit valid={true} />
								)}
							</Fragment>
						) : (
							<Fragment>
								<Button
									variant="contained"
									size="large"
									className={
										!allowNext || maximumPrevious === 0
											? "btn-style disabled"
											: "btn-style"
									}
									disabled={!allowNext || maximumPrevious === 0}
									onClick={(e) => onNext(e)}
									tabIndex={1}
									sx={{ m: 1, fontSize: "0.875rem" }}
								>
									{/* {currentGroupIndex === -1 ? (
										<Fragment>{nextGroupText ?? nextText}</Fragment>
									) : ( */}
										<Fragment>{nextGroupText ?? nextText}</Fragment>
									{/* )} */}
								</Button>
							</Fragment>
						)}
					</Fragment>
				);
				break;
			case NavigationModes.BySection:
				prevButton = (
					<Fragment>
						{(prevGroupText || prevText) && sectionIndex > 0 && (
							<Button
								variant="outlined"
								size="large"
								className={
									!allowPrevious || maximumPrevious === 0
										? "btn-style disabled"
										: "btn-style"
								}
								disabled={!allowPrevious || maximumPrevious === 0}
								onClick={(e) => onPrevious(e)}
								tabIndex={1}
								sx={{ m: 1, fontSize: "0.875rem" }}
							>
								{prevGroupText ?? prevText}
							</Button>
						)}
					</Fragment>
				);

				nextButton = (
					<Fragment>
						{currentSection?.component === "SurveyReviewQuestionsComponent" ||
						currentSection.submitButtonText ? (
							<Fragment>
								{currentSection.reviewable ? (
									<SurveySubmit valid={isValid as boolean} />
								) : (
									<SurveySubmit valid={true} />
								)}
							</Fragment>
						) : (
							<Fragment>
								{sectionIndex <
								activeSurvey.surveyDocument.survey.surveySections.length - 1 ? (
									<Button
										variant="contained"
										size="large"
										className={
											!isValid || !allowNext
												? "btn-style disabled"
												: "btn-style"
										}
										disabled={!isValid || !allowNext}
										onClick={(e) => onNext(e)}
										tabIndex={2}
										sx={{ m: 1, fontSize: "0.875rem" }}
										ref={ref_nextButton}
									>
										{nextGroupText ?? nextText}
									</Button>
								) : (
									<Fragment>
										{currentSection.reviewable ? (
											<SurveySubmit valid={isValid as boolean} />
										) : (
											<SurveySubmit valid={true} />
										)}
									</Fragment>
								)}
							</Fragment>
						)}
					</Fragment>
				);
		}

		return (
			<Grid container>
				<Grid item xs={12} pr={5}>
					{prevButton}
					{nextButton}
				</Grid>
			</Grid>
		);
	};

	const renderByStatus = () => {
		switch (activeSurvey?.surveyDocument?.surveyStatusId) {
			case 0:
			case 1:
			case 2:
				return <Fragment>{renderButtons()}</Fragment>;

			case 4:
				if (surveyReopened) return <Fragment>{renderButtons()}</Fragment>;
				return <Fragment />;

			default:
				return <Fragment />;
		}
	};

	return (
		<Box id="surveyNavigation" className="fixed-bottom">
			<Grid container>
				<Grid item xs={12}>
					{surveyReopened && <Fragment />}
					{activeSurvey && currentSection && (
						<Box
							className="inner buttons"
							sx={{
								textAlign: "right",
								alignItems: "center",
								alignContent: "center",
								justifyContent: "center",
								justifyItems: "center",
								justifySelf: "center",
							}}
						>
							<Fragment>{renderByStatus()}</Fragment>
						</Box>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};
