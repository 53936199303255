import React, { useEffect, useState } from "react";
import VideoJS from "./VideoJS";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEnv } from "../context/env.context";
import { RootState } from "../store";
import { useSelector } from "react-redux";

interface VideoProps {
    source: string,
    type_title: string,
    poster?: string,

    assessment?: string,
    played_from?: string
}

export const VideoPlayerWithAnalytics: React.FC<VideoProps> = ({
	source, type_title, poster, assessment, played_from
}) => {
    const { domain } = useEnv();
    const playerRef = React.useRef(null);
    const { userIdForAnalytics } = useSelector((state: RootState) => state.user);

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // player.on('waiting', () => {
        //   console.log('player is waiting');
        // });

        player.on('play', () => {
            // if (domain === "auth.ccl.org") { //Firebase is Disabled in Lower ENVs #78644
                logEvent(getAnalytics(), `[EVENT] ${type_title} Video`, {
                    "Assessment Title": assessment,
                    "Launched From": played_from,
                    "User": (userIdForAnalytics)
                })
            // }
        }); 
    
        // player.on('dispose', () => {
        //   videojs.log('player will dispose');
        // });
    };

    return (
        <VideoJS 
            poster={poster ?? "https://public-assets.ccl.org/images/icons/ccl/android-chrome-512x512.png"} 
            source={source}
            onReady={handlePlayerReady}
        />
    );
}