import { useAuth0 } from "@auth0/auth0-react";

import { UserInfo } from "../components";
import { Layout } from "../components/chrome/Layout";

export const WhoAmI: React.FC = () => {
	const { user } = useAuth0();

	return (
		<Layout>
			<UserInfo />
		</Layout>
	);
};
